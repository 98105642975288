// @file Function to check for unsaved changes and prompt user to confirm before leaving the page
import { __ } from '@@/bits/intl'

/**
 * This should be called by the `beforeunload` event listener
 */
export const showUnsavedChangesDialog = (event: BeforeUnloadEvent): string => {
  /**
   * There are 3 ways to show the alert:
   * 1. Call event.preventDefault()
   * 2. Set event.returnValue to a truthy value
   * 3. Return a truthy val
   *
   * 1 works for modern browsers, 2 and 3 support legacy cases.
   *
   * Reference: https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
   */

  // (1)
  event.preventDefault()

  // Note: Most modern browsers don't support a custom message
  const alertMessage = __(
    'It looks like you have unsaved changes. Are you sure you want to discard them and close this page?',
  )
  // (2)
  event.returnValue = alertMessage

  // (3)
  return alertMessage
}
